import React from 'react';
import '../footer.css';
import '../index.css';

const Footer = () => {
    return (
        <footer className="footer">
            <div className="footer-container">
                <div className="footer-section">
                    <h3 className='font1'>About Us</h3>
                    <p className='fontdesc'>We are a goods shop located in Goole, East Yorkshire bringing quality items and a premium buy-back service, were proud to be a small local business.</p>
                </div>
                <div className="footer-section">
                    <h3 className='font1'>Contact Us</h3>
                    <p className='fontdesc'>88-90 Boothferry Road, DN146AE, Goole</p>
                    <p className='fontdesc'>01405 800727</p>
                    <p className='fontdesc'><a href="mailto:Sales@cash-it-in-goole.com">Sales@cash-it-in-goole.com</a></p>
                </div>
                <div className="footer-section">
                    <h3 className='font1'>Follow Us</h3>
                    <div className="social-links fontdesc">
                        <a href="https://www.facebook.com/profile.php?id=61558754397480" target="_blank" rel="noopener noreferrer">Facebook</a>
                        <a href="https://www.tiktok.com/@cash.it.in.goole" target="_blank" rel="noopener noreferrer">TikTok</a>
                        <a href="https://x.com/Cash_it_inGoole" target="_blank" rel="noopener noreferrer">Twitter</a>
                        
                    </div>
                </div>
            </div>
            <div className="footer-bottom">
                <p>&copy; 2024 Cash-it-in Goole. Website Created by <a href="mailto:malixn04@gmail.com">Jake Mallinson</a><span class="designer">(MalFabIT)</span> All rights reserved.</p>
            </div>
        </footer>
    );
};

export default Footer;
